import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createDeviceDetector } from 'next-vue-device-detector'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import VueSplide from '@splidejs/vue-splide'

import '@splidejs/vue-splide/css'

const app = createApp(App);
export const device = createDeviceDetector();

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAGMGljOx3xuxkBeIETHb-SyCf4gQyjxRY',
    },
})

router.beforeEach((to, next) => {
    document.title = `${ process.env.VUE_APP_TITLE }`
})

require('@/config/axios');

app.use(router).use(device).use(VueSplide).mount('#client')
