<template>
    <router-view />
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
* {
    font-family: 'Quicksand', sans-serif !important;
}
button {
    &:focus {
        box-shadow: none !important;
    }
}
.bg-base {
    background: linear-gradient(270deg, rgba(249,178,23,1) 0%, rgba(249,178,23,1) 50%, rgba(239,75,42,1) 100%) !important;
}
</style>
