window.axios = require('axios');

axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/api/public';

import { apiGet, apiPost, apiPut, apiDelete } from './api';
window.apiGet = apiGet;
window.apiPost = apiPost;
window.apiPut = apiPut;
window.apiDelete = apiDelete;

